import { DateTool } from "@js/date-tool.js";
import dayjs from "dayjs";

class ReportModel {
  constructor(model) {
    this.model = model;
    //报告显示信息
    this.isCombineBloodOxygenAndPulse = !!parseInt(this.model.reportInfo.bloodPulse)
    this.isCombinePeriodAndEvent = !!parseInt(this.model.reportInfo.stageEvent)
    this.isPage2BreakAtPosition1 = this._initIsPage2BreakAtPosition1()
    this.isPage3BreakAtPosition1 = this._initIsPage3BreakAtPosition1()
    this.isShowAverageBreathRate = this._initIsShowAverageBreathRate()
    // 报告信息
    this.hospitalIcon = this._initHospitalIcon()
    this.hospitalName = this._initHospitalName()
    this.reportModeType = this._initReportModeType()
    this.reportName = this._initReportName()
    //操作员信息
    this.initialOperator = this._initInitialOperator()
    this.initialReviewer = this._initInitialReviewer()
    // 用户信息
    this.initialPatientName = this._initInitialPatientName()
    this.initialPatientHeight = this._initInitialPatientHeight()
    this.initialPatientAge = this._initInitialPatientAge()
    this.initialPatientDepartment = this._initInitialPatientDepartment()
    this.initialPatientAdmissionNumber = this._initInitialPatientAdmissionNumber()
    this.initialPatientGender = this._initInitialPatientGender()
    this.initialPatientBMI = this._initInitialPatientBMI()
    this.initialPatientWeight = this._initInitialPatientWeight()
    this.initialPatientBedNumber = this._initInitialPatientBedNumber()
    this.initialPatientDiagnoseNumber = this._initInitialPatientDiagnoseNumber()
    //时间原始值（后面计算的基础）
    this.startRecordRawTime = this._initStartRecordRawTime()//仪器开始记录时间（体动占比、体动幅度图表开始时间）
    this.ringStartRecordRawTime = this._initRingStartRecordRawTime()//戒指开始记录时间（血氧、脉率图表开始时间）
    this.startPeriodRawTime = this._initStartPeriodRawTime()//睡眠分期开始时间（分期趋势、呼吸事件开始时间）
    this.startTrendRawTime = this._initStartTrendRawTime()//呼吸趋势开始时间（呼吸率开始时间）
    this.startSleepRawTime = this._initStartSleepRawTime()//开始入睡时间
    this.stopRecordRawTime = this._initStopRecordRawTime()//结束记录时间
    this.stopPeriodRawTime = this._initStopPeriodRawTime()//睡眠分期结束时间
    this.stopSleepRawTime = this._initStopSleepRawTime()//结束睡眠时间
    this.startMonitorRawTime = this._initStartMonitorRawTime()//开始监测时间（所有图表的结束时间）
    this.stopMonitorRawTime = this._initStopMonitorRawTime()//结束监测时间（所有图表的结束时间）
    //数据摘要
    this.ahiTitle = this._initahiTitle() //AHI或OAHI（部分儿童）
    this.ahi = this._initahi()
    this.ai = this._initai()
    this.leavebedtimes = this._initLeavebedtimes()
    this.breathRateArray = this._initBreathRateArray()
    this.maxBreathRate = this._initMaxBreathRate()
    this.averageBreathRate = this._initAverageBreathRate()
    this.minBreathRate = this._initMinBreathRate()
    //睡眠时间统计
    this.startRecordTime = this._initStartRecordTime()
    this.startSleepTime = this._initStartSleepTime()
    this.createDate = this._initCreateDate() //和stopRecord取同一个时间
    this.stopRecordTime = this._initStopRecordTime()
    this.stopSleepTime = this._initStopSleepTime()
    this.sleepEfficiency = this._initSleepEfficiency()
    this.sleepTime = this._initSleepTime()
    //睡眠呼吸事件
    this.averageApneaTime = this._initAverageApneaTime()//平均暂停和低通气时间
    this.maxApneaTime = this._initMaxApneaTime()//最长暂停和低通气时间
    this.maxApneaTip = this._initMaxApneaTip()//最长暂停和低通气发生时间
    this.breathEventCount = this._initBreathEventCount()//总呼吸事件数
    this.breathEventTime = this._initBreathEventTime()//总呼吸事件时间
    this.breathEventRate = this._initBreathEventRate()//占总记录时间
    this.beohCnt = this._initBEOHCnt()//阻塞及低通气事件数
    this.beoCnt = this._initBEOCnt()//阻塞事件数
    this.behCnt = this._initBEHCnt()//低通气事件数
    this.csaCount = this._initcsaCount()//中枢性呼吸事件次数
    this.msaCount = this._initmsaCount()//混合性呼吸事件次数
    //睡眠分期时间
    this.awakeTime = this._initAwakeTime()
    this.awakeRatio = this._initAwakeRatio()
    this.remTime = this._initremTime()
    this.remRatio = this._initremRatio()
    this.lightSleepTime = this._initLightSleepTime()
    this.lightSleepRatio = this._initLightSleepRatio()
    this.deepSleepTime = this._initDeepSleepTime()
    this.deepSleepRatio = this._initDeepSleepRatio()
    this.pieArray = this._initPieArray()
    //血氧统计
    this.averageBloodOxygenSaturation = this._initAverageBloodOxygenSaturation()//平均血氧饱和度
    this.minBloodOxygenSaturation = this._initMinBloodOxygenSaturation()//最低血氧饱和度
    this.oxygenRadiationTimes = this._initOxygenRadiationTimes()//氧减次数
    this.oxygenRadiationIndex = this._initOxygenRadiationIndex()//氧减指数
    this.bloodOxygen95LastingTime = `${parseInt(this._initBloodOxygen95LastingTime() / 60)}`
    this.bloodOxygen95Rate = this._initBloodOxygen95Rate()
    this.bloodOxygen90LastingTime = `${parseInt(this._initBloodOxygen90LastingTime() / 60)}`
    this.bloodOxygen90Rate = this._initBloodOxygen90Rate()
    this.bloodOxygen85LastingTime = `${parseInt(this._initBloodOxygen85LastingTime() / 60)}`
    this.bloodOxygen85Rate = this._initBloodOxygen85Rate()
    this.bloodOxygen80LastingTime = `${parseInt(this._initBloodOxygen80LastingTime() / 60)}`
    this.bloodOxygen80Rate = this._initBloodOxygen80Rate()
    //脉率统计
    this.averagePulse = this._initAveragePulse()
    this.maxPulse = this._initMaxPulse()
    this.minPulse = this._initMinPulse()
    //AHI
    this.ahiDegreeArray = this._initahiDegreeArray()
    //评估建议
    this.ahiLevel = this._initahiLevel()
    this.evaluateInfo = this._initEvaluateInfo()
    this.isEditable = this._initIsEditable()
    this.analysis = this._initAnalysis()
    this.description = this._initDescription()
    this.suggestion = this._initSuggestion()
    this.conclusion = this._initConclusion()
    //图表
    this.timeTickArray = this._initTimeTickArray()
    this.secondTimeArray = this._composeTimeArray(true)
    this.minuteTimeArray = this._composeTimeArray(false)
    //是否分别展示阻塞及低通气
    this.isSeprateDisplayBEOH = !!this.model.hyponea
    //各图表数据为了省内存使用get方法
  }

  _initIsPage2BreakAtPosition1() {
    return this.isCombineBloodOxygenAndPulse != true
  }

  _initIsPage3BreakAtPosition1() {
    return !this.isCombineBloodOxygenAndPulse && !this.isCombinePeriodAndEvent
  }

  _initIsShowAverageBreathRate() {
    return this.model.reportInfo.hospitalId == 351 //浙江大学医学院附属妇产科医院
  }

  _initHospitalIcon() {
    return this.model.reportInfo.reportLogo;
  }

  _initHospitalName() {
    return this.model.reportInfo.reportTitle;
  }

  _initReportModeType() {
    return this.model.reportInfo.modeType
  }

  _initReportName() {
    if (this.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
      return "睡眠呼吸报告（儿童模式）";
    } else {
      return "睡眠呼吸报告";
    }
  }

  _initCreateDate() {
    const time = DateTool.timestampToStr(
      this.stopRecordRawTime,
      "Y年M月D日"
    );
    return time;
  }

  _initInitialOperator() {
    return this.model.userInfo.report;
  }

  _initInitialReviewer() {
    return this.model.userInfo.examine;
  }

  _initInitialPatientName() {
    return this.model.userInfo.name
  }

  _initInitialPatientHeight() {
    return this.model.userInfo.height
  }

  _initInitialPatientAge() {
    if (this.model.userInfo.age && this.model.userInfo.age != 0) {
      return this.model.userInfo.age;
    }
    const birthDay = dayjs(this.model.userInfo.birthday)
    const baseDate = dayjs(this.model.reportInfo.createDat).toDate()
    return DateTool.calculateAgeWithBaseDate(birthDay.year(), birthDay.month() + 1, birthDay.date(), baseDate);
  }

  _initInitialPatientDepartment() {
    return this.model.userInfo.department
  }

  _initInitialPatientAdmissionNumber() {
    return this.model.userInfo.zyh
  }

  _initInitialPatientGender() {
    if (this.model.userInfo.sex == 1) {
      return "男"
    } else if (this.model.userInfo.sex == 2) {
      return "女"
    } else {
      return this.model.userInfo.sex
    }

  }

  _initInitialPatientBMI() {
    return this.model.userInfo.bmi
  }

  _initInitialPatientWeight() {
    return this.model.userInfo.weight
  }

  _initInitialPatientBedNumber() {
    return this.model.userInfo.bedNo
  }

  _initInitialPatientDiagnoseNumber() {
    return this.model.userInfo.mzh
  }

  _initahi() {
    return this.model.statistical.ahi;
  }

  _initai() {
    return this.model.statistical.ai
  }

  _initahiTitle() {
    if (this.reportModeType == DEVICE_MONITOR_MODE.儿童模式 && this.model.statistical.oahi != "") {
      return "OAHI"; //oahi和ahi值都是一样的，只是名称不同
    } else {
      return "AHI";
    }
  }

  _initahiLevel() {

    return this.model.reportInfo.ahiLevel
  }

  _initLeavebedtimes() {
    return this.model.statistical.leaveBedTimes;
  }

  _initBreathRateArray() {
    return this.model.detailedArr.bmprlist
      .map((item) => {
        return item[0];
      })
      .filter((item) => {
        return item != 0
      })
  }

  _initMaxBreathRate() {
    if (this.breathRateArray.length == 0) {
      return ''
    } else {
      return `${Math.max(...this.breathRateArray)}`;
    }

  }

  _initAverageBreathRate() {
    const rawArray = this.model.detailedArr.bmprlist.map((item) => {
      return item[0]
    })
    const validArray = rawArray.filter((item) => {
      return item != 0
    })
    let sum = 0
    for (const item of validArray) {
      sum += item
    }
    const average = sum / validArray.length
    return `${average.toFixed(0)}`;
  }

  _initMinBreathRate() {
    if (this.breathRateArray.length == 0) {
      return ''
    } else {
      return `${Math.min(...this.breathRateArray)}`;
    }

  }

  _initStartRecordTime() {
    const time = DateTool.timestampToStr(
      this.startRecordRawTime,
      "h：m"
    );
    return time;
  }

  _initStartSleepTime() {
    const time = DateTool.timestampToStr(
      this.startSleepRawTime,
      "h：m"
    );
    return time;
  }

  _initStopRecordTime() {
    const time = DateTool.timestampToStr(
      this.stopRecordRawTime,
      "h：m"
    );
    return time;
  }

  _initStopSleepTime() {
    const time = DateTool.timestampToStr(
      this.stopSleepRawTime,
      "h：m"
    );
    return time;
  }
  _initSleepEfficiency() {
    return this.model.statistical.sleepEfficiency;
  }

  _initSleepTime() {
    const totalMinutes = this.model.statistical.remTime + this.model.statistical.lightSleepTime + this.model.statistical.deepSleepTime
    const hour = parseInt(totalMinutes / 60)
    const minute = totalMinutes - hour * 60
    return `${hour}小时${minute}分钟`
  }

  _initAverageApneaTime() {
    return `${this.model.statistical.beMeanLen}`;
  }

  _initMaxApneaTime() {
    return `${this.model.statistical.beMaxLen}`;
  }

  _initMaxApneaTip() {
    if (this.model.statistical.beMeanlen == 0) {
      return `该事件发生于：--`
    } else {
      //事件发生时间早于开始入睡时间（精确到分钟）
      if (this.model.statistical.beMaxLenTime < this.startSleepRawTime - 60) {
        return `该事件发生于：--`
      } else {
        const time = DateTool.timestampToStr(
          this.model.statistical.beMaxLenTime,
          "h：m"
        );
        return `该事件发生于：${time}`;
      }
    }
  }

  _initBreathEventCount() {
    return `${this.model.statistical.beCnt}`;
  }

  _initBreathEventTime() {
    return `${this.model.statistical.beTotalTime}`;
  }

  _initBreathEventRate() {
    return `${this.model.statistical.beTotalRate}`;
  }

  _initBEOHCnt() {
    return `${this.model.statistical.beOhCnt}`;
  }

  _initBEOCnt() {
    return `${this.model.statistical.beoCnt}`
  }

  _initBEHCnt() {
    return `${this.model.statistical.behCnt}`
  }

  _initcsaCount() {
    return `${this.model.statistical.becCnt}`;
  }

  _initmsaCount() {
    return `${this.model.statistical.bemCnt}`;
  }

  _initAwakeTime() {
    return `${this.model.statistical.wakeTime}`;
  }

  _initAwakeRatio() {
    return this.model.statistical.wakeRate;
  }

  _initremTime() {
    return `${this.model.statistical.remTime}`;
  }

  _initremRatio() {
    return `${this.model.statistical.remRate}`;
  }

  _initLightSleepTime() {
    return `${this.model.statistical.lightSleepTime}`;
  }

  _initLightSleepRatio() {
    return `${this.model.statistical.lightSleepRate}`;
  }

  _initDeepSleepTime() {
    return `${this.model.statistical.deepSleepTime}`;
  }

  _initDeepSleepRatio() {
    return `${this.model.statistical.deepSleepRate}`;
  }

  _initPieArray() {
    return [
      {
        type: "清醒期",
        value: this.model.statistical.wakeTime
      },
      {
        type: "眼动期",
        value: this.model.statistical.remTime

      },
      {
        type: "浅睡期",
        value: this.model.statistical.lightSleepTime
      },
      {
        type: "深睡期",
        value: this.model.statistical.deepSleepTime
      },
    ];
  }
  _initAverageBloodOxygenSaturation() {
    return `${this.model.statistical.spo2avg}`;
  }

  _initMinBloodOxygenSaturation() {
    return `${this.model.statistical.spo2min}`;
  }

  _initOxygenRadiationTimes() {
    return `${this.model.statistical.diffThdLge3Cnts}`;
  }

  _initOxygenRadiationIndex() {
    return `${this.model.statistical.diffThdLge3Pr}`;
  }

  _initBloodOxygen95LastingTime() {
    return Number(this.model.statistical.spo2Less95time);
  }

  _initBloodOxygen95Rate() {
    return `${this.model.statistical.spo2Less9TimePercent}`;
  }

  _initBloodOxygen90LastingTime() {
    return Number(this.model.statistical.spo2Less90time);
  }

  _initBloodOxygen90Rate() {
    return `${this.model.statistical.spo2Less90TimePercent}`;
  }

  _initBloodOxygen85LastingTime() {
    return Number(this.model.statistical.spo2Less85time);
  }

  _initBloodOxygen85Rate() {
    return `${this.model.statistical.spo2Less85TimePercent}`;
  }

  _initBloodOxygen80LastingTime() {
    return Number(this.model.statistical.spo2Less80time);
  }

  _initBloodOxygen80Rate() {
    return `${this.model.statistical.spo2Less80TimePercent}`;
  }

  _initAveragePulse() {
    return `${this.model.statistical.prAvg}  bpm`;
  }

  _initMaxPulse() {
    return `${this.model.statistical.prMax}  bpm`;
  }

  _initMinPulse() {
    return `${this.model.statistical.prMin}  bpm`;
  }

  _initStartRecordRawTime() {
    return parseInt(this.model.statistical.startSleepTime / 1000)
  }

  _initRingStartRecordRawTime() {
    return this.model.statistical.ringTimeStart
  }

  _initStartPeriodRawTime() {
    return this.startRecordRawTime + this.model.statistical.startStatusTimeMinute * 60
  }

  _initStartTrendRawTime() {
    return this.model.detailedArr.bmprlistInfo.startTime
  }

  _initStartSleepRawTime() {
    return this.startRecordRawTime + this.model.statistical.fallSleepTimeMinute * 60
  }

  _initStopRecordRawTime() {
    return this.startRecordRawTime + this.model.statistical.extraCheckTimeMinute * 60
  }

  _initStopPeriodRawTime() {
    return this.startRecordRawTime + this.model.statistical.endStatusTimeMinute * 60
  }

  _initStartMonitorRawTime() {
    return Math.max(this.startSleepRawTime - 15 * 60, this.startRecordRawTime, this.startPeriodRawTime)
  }

  _initStopSleepRawTime() {
    if (this.model.statistical.endSleepTimeMinute == 0) {
      //睡眠分期数组尾部0值个数
      let zeroCount = 0
      const sleepStagesReverseArray = this.model.detailedArr.sleepdata.reverse()
      for (const item of sleepStagesReverseArray) {
        if (item == 0) {
          zeroCount += 1
        } else {
          break
        }
      }
      //真实的睡眠分期结束时间
      const endStatusTimeMinute = this.model.statistical.endStatusTimeMinute - zeroCount
      return this.startRecordRawTime + endStatusTimeMinute * 60
    } else {
      return this.startRecordRawTime + this.model.statistical.endSleepTimeMinute * 60
    }
  }

  _initStopMonitorRawTime() {
    return Math.min(this.stopRecordRawTime, this.stopSleepRawTime + 15 * 60, this.stopPeriodRawTime)
  }

  //睡眠血氧趋势图表数据
  get bloodOxygenChartDataArray() {
    const isSecond = true
    const startTime = this.ringStartRecordRawTime
    const valueArray = this.model.detailedArr.spo2arr.map((item) => {
      return item == 0 ? undefined : item
    })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  //睡眠脉率趋势图表数据
  get pulseChartDataArray() {
    const isSecond = true
    const startTime = this.ringStartRecordRawTime
    const valueArray = this.model.detailedArr.prarr.map((item) => {
      return item == 0 ? undefined : item
    })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  //睡眠呼吸趋势图表数据
  get breathChartDataArray() {
    const isSecond = false
    const startTime = this.startTrendRawTime
    const valueArray = this.model.detailedArr.bmprlist
      .map((item) => { return item[0] == 0 ? undefined : item[0] })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  //睡眠分期趋势图表数据
  get breathPeriodChartDataArray() {
    const isSecond = false
    const startTime = this.startPeriodRawTime
    const valueArray = this.model.detailedArr.sleepdata.map((item) => {
      switch (item) {
        case 0: //清醒
          return 4
        case 2: //眼动
          return 3
        case 3: //浅睡
          return 2
        case 4: //深睡
          return 1
        default:
          return 4;
      }
    })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  //睡眠呼吸事件图表数据
  get breathEventChartDataArray() {
    const isSecond = true
    const startTime = this.startPeriodRawTime
    const timeArray = this.secondTimeArray
    let valueArray = []
    let dataArray = [...this.model.detailedArr.breatheventvect]
    timeArray.forEach((item, index) => {
      let targetIndex = -1
      if (dataArray.length > 0) {
        targetIndex = dataArray.findIndex(el => el[0] === item)
      }
      if (targetIndex !== -1) {
        valueArray[index] = [item * 1000, dataArray[targetIndex][1]]
        dataArray.splice(targetIndex, 1)
      } else {
        valueArray[index] = [item * 1000, void 0]
      }
    })
    return valueArray
  }

  //睡眠体动占比图表数据
  get moveRateChartDataArray() {
    const isSecond = false
    const startTime = this.startRecordRawTime
    const valueArray = this.model.detailedArr.bodymovelist.map((item) => { return item[0] })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  //睡眠体动幅度图表数据
  get moveRangeChartDataArray() {
    const isSecond = false
    const startTime = this.startRecordRawTime
    const valueArray = this.model.detailedArr.bodymovelist.map((item) => { return item[1] })
    return this._composeDataArray(isSecond, startTime, valueArray)
  }

  _initTimeTickArray() {
    //XTickArray
    let secondPerHour = 60 * 60; //每小时的毫数
    let startTimestamp = this.startMonitorRawTime;
    let startIntTimestamp = //开始时前的整点时间戳
      Math.floor(startTimestamp / secondPerHour) * secondPerHour;
    let endTimestamp = this.stopMonitorRawTime;
    let endIntTimestamp = //结束时后的整点时间戳
      Math.ceil(endTimestamp / secondPerHour) * secondPerHour;
    let XTickCount = (endIntTimestamp - startIntTimestamp) / secondPerHour;
    let XTickArray = [];
    //加入每个整点时间戳
    for (let i = 0; i <= XTickCount; i++) {
      let intTimeStamp = startIntTimestamp + i * secondPerHour;
      XTickArray.push(intTimeStamp);
    }
    //真实开始时间替换首位整点时间戳
    XTickArray[0] = startTimestamp;
    //如果第二位整点时间戳与真实开始时间相差不到30分钟，剔除
    if (XTickArray[1] - XTickArray[0] < 0.5 * secondPerHour) {
      XTickArray.splice(1, 1);
    }
    //真实结束时间替换末位整点时间戳
    XTickArray[XTickArray.length - 1] = endTimestamp;
    //如果倒数第二位整点时间戳与真实结束时间相差不到30分钟，剔除
    if (
      XTickArray[XTickArray.length - 1] - XTickArray[XTickArray.length - 2] <
      0.5 * secondPerHour
    ) {
      XTickArray.splice(XTickArray.length - 2, 1);
    }
    return XTickArray.map((item) => { return item * 1000 });
  }

  _initahiDegreeArray() {
    if (this.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
      if (this.model.reportInfo.standard == CHILD_STANDDARD_TYPE_CODE["2007版中国指南"]) {
        return AHI_DEGREE_ARRAY.中国儿童;
      } else {
        return AHI_DEGREE_ARRAY.美国儿童;
      }
    } else {
      return AHI_DEGREE_ARRAY.成人;
    }
    // switch (this.reportModeType) {
    //   case DEVICE_MONITOR_MODE.儿童模式:
    //     switch (`${this.model.reportInfo.standard}`) {
    //       case CHILD_STANDDARD_TYPE_CODE["2007版中国指南"]:
    //         return AHI_DEGREE_ARRAY.中国儿童;
    //       case CHILD_STANDDARD_TYPE_CODE["2020版中国指南/2014版美国指南"]:
    //         return AHI_DEGREE_ARRAY.美国儿童;
    //     }
    //   case DEVICE_MONITOR_MODE.成人模式:
    //     return AHI_DEGREE_ARRAY.成人;
    // }
  }

  _initEvaluateInfo() {
    return this.model.reportInfo.evaluate
  }

  _initIsEditable() {
    return this.evaluateInfo.type != REPORT_SUGGESTION_TYPE.智能评估;
  }

  _initConclusion() {

    if (this.evaluateInfo.customize == null) {
      return this.evaluateInfo.customize;
    } else {
      switch (this.evaluateInfo.type) {
        case REPORT_SUGGESTION_TYPE.空白模板:
          return "";
        default:
          // return `${this.analysis}${this.description}${this.suggestion}`;
          return this.evaluateInfo.customize;
      }
    }
  }

  _initAnalysis() {
    //为了设置的粗细和字体正常显示，需要在reset.css文件中把b标签的"font: inherit"注释掉
    return `<p><b><font size="3">睡眠分期分析：</font></b></p><p><font size="2">您的总睡眠时间为${this.sleepTime}，睡眠效率(TST/TIB)为${this.sleepEfficiency}，&nbsp;其中深睡期占比${this.deepSleepRatio}%，浅睡期占比${this.lightSleepRatio}%，快速眼动期占比${this.remRatio}%。</font></p><p><font size="2"><br/></font></p>`;
  }

  _initDescription() {
    let sleepBreathStatusStr = "";
    switch (this.ahiLevel) {
      case AHI_LEVEL_CODE.正常:
        sleepBreathStatusStr = "睡眠呼吸状态正常";
        break;
      default:
        sleepBreathStatusStr = `符合&nbsp;【${this.ahiLevel}】&nbsp;睡眠呼吸暂停低通气综合征的诊断`;
        break;
    }
    let bloodOxygenStatusStr = "";
    let bloodOxygenLevel = 0
    switch (true) {
      case this.minBloodOxygenSaturation >= BLOOD_OXYGEN_STANDARD.轻度: //90
        break
      case this.minBloodOxygenSaturation >= BLOOD_OXYGEN_STANDARD.中度://85
        bloodOxygenLevel = BLOOD_OXYGEN_STANDARD.轻度
        break
      case this.minBloodOxygenSaturation >= BLOOD_OXYGEN_STANDARD.重度://80
        bloodOxygenLevel = BLOOD_OXYGEN_STANDARD.中度
        break
      default:
        bloodOxygenLevel = BLOOD_OXYGEN_STANDARD.重度
        break;
    }
    bloodOxygenStatusStr = bloodOxygenLevel == 0 ?
      `（标准值：≥90%（正常））。` :
      `（标准值：<${bloodOxygenLevel}%（${findKey(BLOOD_OXYGEN_STANDARD, bloodOxygenLevel)}）），符合&nbsp;【${findKey(BLOOD_OXYGEN_STANDARD, bloodOxygenLevel)}】&nbsp;低氧血症诊断。`;


    return `<p><b><font size="3">睡眠呼吸综述：</font></b></p><p><font size="2">您的${this.ahiTitle}指数为${this.ahi}，&nbsp;${sleepBreathStatusStr}。&nbsp;夜间平均血氧饱和度为${this.averageBloodOxygenSaturation}%，&nbsp;最低血氧饱和度为${this.minBloodOxygenSaturation}%${bloodOxygenStatusStr}</font></p><p><b><font size="2"><br/></font></b></p>`;
  }

  _initSuggestion() {
    switch (this.ahiLevel) {
      case AHI_LEVEL_CODE.正常:
        return `<p><b><font size="3">建议：</font></b></p><p><font size="2">1.保持良好的生活习惯；</font></p><p><font size="2">2.适当运动，增强体质；</font></p><p><font size="2">3.定期进行睡眠监测；</font></p>`;
      default:
        let habit = "";
        let bmi = "";
        switch (this.modeType) {
          case DEVICE_MONITOR_MODE.儿童模式:
            habit = "饮食宜清淡";
            bmi = "控制BMI（身体质量指数）";
            break;
          case DEVICE_MONITOR_MODE.成人模式:
            habit = "饮食宜清淡，戒烟、戒酒";
            bmi = "控制BMI（身体质量指数）在18.5-24";
            break;
        }
        return `<p><b><font size="3">建议：</font></b></p><p><font size="2">1.${habit}适当运动，增强体质${bmi}；使用右侧卧位睡姿入眠；慎用或停用镇静、安眠药物；</font></p><p><font size="2">2.耳鼻喉科就诊，了解有无口或咽喉部位解剖学异常，必要时进行鼻咽部CT/MRI了解有无手术指征；</font></p><p><font size="2">3.排除上呼吸道病变后，转睡眠呼吸专科就诊，经压力滴定测试后，无论是否手术均建议夜间无创正压通气治疗；</font></p><p><font size="2">4.最好连续的睡眠监测，治疗3-6月后进行门诊复诊；</font></p>`;
    }
  }

  _composeTimeArray(isSecond) {
    let timeArray = []
    let step = isSecond ? 1 : 60
    let time = this._time20To8(this.startMonitorRawTime, this.stopMonitorRawTime)
    let maxIndex = (time[1] - time[0]) / step
    for (let index = 0; index <= maxIndex; index++) {
      const timeItem = time[0] + index * step
      timeArray.push(timeItem)
    }
    return timeArray
  }

  _time20To8(startTime, endTime) {
    const current = new Date(endTime * 1000); // 转换为毫秒级时间戳

    // 获取当前日期的年、月、日
    const year = current.getFullYear();
    const month = current.getMonth();
    const day = current.getDate();

    // 创建上午8点和晚上20点的时间对象
    // 创建上午8点和晚上20点的时间对象
    const morning8 = new Date(year, month, day, 8, 0, 0);
    const evening20 = new Date(year, month, day, 20, 0, 0);
    let start, end;
    if (current <= morning8) {
      start = new Date(year, month, day - 1, 20, 0, 0);
      end = morning8;
    } else {
      start = evening20;
      end = new Date(year, month, day + 1, 8, 0, 0);
    }
    return [start.getTime() / 1000, end.getTime() / 1000]
  }

  _composeDataArray(isSecond, startTime, valueArray) {
    let dataArray = []
    const timeArray = isSecond ? this.secondTimeArray : this.minuteTimeArray
    const step = isSecond ? 1 : 60
    timeArray.forEach((item) => {
      let index = parseInt((item - startTime) / step)
      let dataItem = []
      if (index < 0 || index >= valueArray.length) {
        dataItem = [item * 1000, undefined]
      } else {
        dataItem = [item * 1000, valueArray[index]]
      }
      dataArray.push(dataItem)
    })
    return dataArray
  }
}

export { ReportModel };
